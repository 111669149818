$('[data-toggle="item"]').click(function(){
	var alvo = $(this).data('target')
	$(alvo).toggleClass('active');
	$('[data-toggle="item"]').toggleClass('active');
	if($('[data-toggle="item"]').hasClass('active')){
		$('[data-toggle="item"]').addClass("fa-angle-up");
		$('[data-toggle="item"]').removeClass("fa-angle-down");
	}else{
		$('[data-toggle="item"]').addClass("fa-angle-down");
		$('[data-toggle="item"]').removeClass("fa-angle-up");
	}
});